import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, Typography, Collapse, Icon, Tag } from 'antd';
import UserContext from 'auth/UserContext';
import EntityForm from './EntityForm';
import CardCollapse from 'components/common/collapse/CardCollapse';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import { trackComponent } from 'telemetry/AppInsights';
import { getEntityById, getEmployeesWithEntity } from '../../../api/entitiesApi';
import { EntityDetail } from 'types/EntityDetail';
import { EmployeeBasic } from 'types/EmployeeBasic';
import FullPageSpinner from 'components/common/FullPageSpinner';

const EntityDetailPage = props => {
  const [isEditMode, setEditMode] = useState(false);
  const [entity, setEntity] = useState<EntityDetail | null>(null);
  const [employees, setEmployees] = useState<EmployeeBasic[]>([]);
  const entityId = props.match.params.entityId;

  useEffect(() => {
    getEntityById(entityId)
      .then(response => {
        setEntity(response);
      })
      .catch(displayErrorNotification);

    getEmployeesWithEntity(entityId)
      .then(response => {
        setEmployees(response);
      })
      .catch(displayErrorNotification);
  }, []);

  if (!entity || !employees) return <FullPageSpinner />;

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = entity => {
    setEntity(entity);
    toggleEditMode();
  };

  if (isEditMode) {
    return (
      <Stack>
        <EntityForm onCancel={toggleEditMode} entity={entity} isCreateMode={false} onSubmit={handleSubmit} />
      </Stack>
    );
  }

  let activePanels = ['employees'];

  const employeeColumns = [
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => <Link to={`/employees/${record.id}`}>{record.fullName}</Link>,
      sorter: (a, b) => a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase())
    },
    {
      title: 'Office',
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => a.location.toLowerCase().localeCompare(b.location.toLowerCase())
    }
  ];

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          <SplitPageHeaderLayout.Layout>
            <SplitPageHeaderLayout.Left>
              <Typography.Title level={1} style={{ marginBottom: '10px' }}>
                {entity.name}
                {ctx.user.permissions.canUpdateEntity && (
                  <Button
                    style={{ lineHeight: '0', fontSize: '20px' }}
                    icon="edit"
                    type="link"
                    onClick={toggleEditMode}
                  />
                )}
              </Typography.Title>
            </SplitPageHeaderLayout.Left>
          </SplitPageHeaderLayout.Layout>

          <Collapse bordered={false} defaultActiveKey={activePanels}>
            <CardCollapse.Panel
              key="employees"
              header={
                <>
                  <Icon type="contacts" /> <span style={{ marginLeft: '5px' }}>Employees</span>
                </>
              }
              extra={
                <>
                  <Tag>
                    {employees && employees.length} Employee
                    {employees && employees.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                rowKey={record => record.id.toString()}
                dataSource={employees}
                columns={employeeColumns}
                pagination={false}
                style={{ width: '100%' }}
              />
            </CardCollapse.Panel>
          </Collapse>
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(EntityDetailPage, 'Entity Detail Page');

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Table, Typography, Collapse, Icon, Tag, Tooltip } from 'antd';
import { isEmpty } from 'lodash';
import UserContext from 'auth/UserContext';
import { getSkillById, getEmployeesWithSkill } from 'api/skillsApi';
import { getProjectExperienceBySkillId } from 'api/projectExperienceApi';
import { changeDelimiter, showParentSkills, textOverflow } from 'utils/skillDetails';
import SkillForm from './SkillForm';
import CardCollapse from 'components/common/collapse/CardCollapse';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import Stack from 'components/common/Stack';
import displayErrorNotification from 'utils/displayErrorNotification';
import formatMonthDate from 'utils/formatMonthDate';
import formatDate from 'utils/formatDate';
import { trackComponent } from 'telemetry/AppInsights';
import { ColumnProps } from 'antd/lib/table';
import { SkillDetail } from 'types/SkillDetail';
import FullPageSpinner from 'components/common/FullPageSpinner';
import { enumExperienceLevels, enumSkillDuration } from 'utils/enumToDisplayText';
import { useFeatures } from 'hooks/FeatureHooks';
import { EmployeeSkillDetail } from 'types/EmployeeSkillDetail';
import { convertEmployeeSkillDetail, convertSkillProject } from 'utils/convert';
import { SkillProject } from 'types/SkillProject';
import sorters from 'utils/sorters'
import ExperienceLevelModal from 'components/skills/skill/ExperienceLevelModal';
import './InfoIcon.css';

const SkillDetailPage = (props: { match: { params: { skillId: number } } }) => {
  const features = useFeatures();
  const [isEditMode, setEditMode] = useState(false);
  const [skill, setSkill] = useState<SkillDetail | null>(null);
  const [employees, setEmployees] = useState<EmployeeSkillDetail[]>([]);
  const [projects, setProjects] = useState<SkillProject[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const skillId: number = props.match.params.skillId;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getSkillById(skillId)
      .then(setSkill)
      .catch(displayErrorNotification);

    getEmployeesWithSkill(skillId)
      .then(response => {
        const converted: EmployeeSkillDetail[] = convertEmployeeSkillDetail(response);
        setEmployees(converted);
      })
      .catch(displayErrorNotification);

    setLoading(true);
    getProjectExperienceBySkillId(skillId)
      .then(response => {
        const converted: SkillProject[] = convertSkillProject(response);
        setProjects(converted);
      })
      .catch(displayErrorNotification)
      .then(() => {
        setLoading(false);
      });
  }, []);

  if (!skill || !employees) return <FullPageSpinner />;

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = (skill: SkillDetail) => {
    setSkill(skill);
    toggleEditMode();
  };

  if (isEditMode) {
    return (
      <Stack>
        <SkillForm onCancel={toggleEditMode} skill={skill} isCreateMode={false} onSubmit={handleSubmit} />
      </Stack>
    );
  }

  let activePanels = ['employees', 'projects'];

  const employeeColumns: ColumnProps<EmployeeSkillDetail>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <Link to={`/employees/${record.id}`}>{record.name}</Link>,
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'practice',
      key: 'practice',
      sorter: (a, b) => a.practice.toLowerCase().localeCompare(b.practice.toLowerCase())
    },
    {
      title: 'Major',
      dataIndex: 'major',
      key: 'major',
      sorter: (a, b) => a.major.toLowerCase().localeCompare(b.major.toLowerCase())
    },
    {
      title: 'Minor',
      dataIndex: 'minor',
      key: 'minor',
      render: (text, record) => textOverflow(changeDelimiter(record.minor), 30),
      width: '20%'
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      sorter: (a, b) => a.position.toLowerCase().localeCompare(b.position.toLowerCase())
    },
    {
      title: 'Office',
      dataIndex: 'office',
      key: 'office',
      sorter: (a, b) => a.office.toLowerCase().localeCompare(b.office.toLowerCase())
    },
    {
      title:
        features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') ? (
          <>
            Experience Level
            <Icon type="info-circle" onClick={() => { setIsModalVisible(true) }} className="info-icon" />
          </>
        ) : (
          'Experience Level'
        ),
      dataIndex: 'experienceLevel',
      key: 'experienceLevel',
      render: (text, record) => enumExperienceLevels(record.experienceLevel),
      sorter: (a, b) => sorters.number(a.experienceLevel, b.experienceLevel)
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (text, record) => enumSkillDuration(record.duration),
      sorter: (a, b) =>
        enumSkillDuration(a.duration)
          .toLowerCase()
          .localeCompare(enumSkillDuration(b.duration).toLowerCase())
    },
    {
      title: 'Last Used',
      dataIndex: 'lastUsedDate',
      key: 'lastUsedDate',
      render: (text, record) => formatMonthDate(record.lastUsedDate),
      sorter: (a, b) => sorters.date(a.lastUsedDate, b.lastUsedDate)
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (text, record) => (record.interest ? 'Yes' : 'No'),
      sorter: (a, b) => (a.interest < b.interest ? -1 : 1)
    }
  ];

  const projectColumns: ColumnProps<SkillProject>[] = [
    {
      title: 'Client',
      key: 'client',
      dataIndex: 'client',
      render: (client, record) => <Link to={`/clients/${record.clientId}`}>{client}</Link>,
      sorter: (a, b) => a.client.toLowerCase().localeCompare(b.client.toLowerCase())
    },
    {
      title: 'Project',
      key: 'project',
      dataIndex: 'project',
      render: (project, record) => <Link to={`/projects/${record.projectId}`}>{project}</Link>,
      sorter: (a, b) => a.project.toLowerCase().localeCompare(b.project.toLowerCase())
    },
    {
      title: 'Active',
      dataIndex: 'active',
      align: 'center',
      render: active => <Tag color={active ? 'green' : 'red'}>{active ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => (a.active < b.active ? -1 : 1)
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: formatDate,
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: formatDate,
      sorter: (a, b) => (a.endDate < b.endDate ? -1 : 1)
    }
  ];

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          {!skill.active && (
            <Alert type="warning" showIcon style={{ marginBottom: '1rem' }} message="This skill is inactive" />
          )}

          <SplitPageHeaderLayout.Layout>
            <SplitPageHeaderLayout.Left>
              <Typography.Title level={1} style={{ marginBottom: '0' }}>
                {skill.name}
                {ctx.user.permissions.canUpdateSkillType && (
                  <Button
                    style={{ lineHeight: '0', fontSize: '20px' }}
                    icon="edit"
                    type="link"
                    onClick={toggleEditMode}
                  />
                )}
              </Typography.Title>
              <Typography.Title level={3} type="secondary" style={{ marginTop: '0', marginBottom: '1rem' }}>
                {skill.category.name}
                {!isEmpty(skill.parentSkill) && ` / ${showParentSkills(skill.parentSkill)}`}
                {!isEmpty(skill.tags) && (
                  <span style={{ marginLeft: '.5rem' }}>
                    {skill.tags.map(t => (
                      <Tag key={'scouttag_' + t.id} color={t.inactive ? 'red' : ''}>
                        {t.name}
                      </Tag>
                    ))}
                  </span>
                )}
              </Typography.Title>

              {skill.description && (
                <Typography.Paragraph style={{ marginTop: '0', marginBottom: '1rem' }}>
                  {skill.description}
                </Typography.Paragraph>
              )}
              {features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography.Paragraph style={{ marginBottom: '1rem' }}>
                    Skill Experience is periodically synced with Projector. Projector Skill Experience of
                    0 = None, 1 = Limited, 2 = Some, 3 = Substantial, 4 = Extensive, 5 = SME.
                    <Icon type="info-circle" onClick={() => { setIsModalVisible(true) }} className="info-icon"/>
                  </Typography.Paragraph>
                </div>
              )}
            </SplitPageHeaderLayout.Left>
          </SplitPageHeaderLayout.Layout>

          <Collapse bordered={false} defaultActiveKey={activePanels}>
            <CardCollapse.Panel
              key="employees"
              header={
                <>
                  <Icon type="contacts" /> <span style={{ marginLeft: '5px' }}>Employees</span>
                </>
              }
              extra={
                <>
                  <Tag>
                    {employees && employees.length} Employee
                    {employees && employees.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                rowKey={record => record.id.toString()}
                dataSource={employees}
                columns={employeeColumns}
                pagination={false}
                style={{ width: '100%' }}
              />
            </CardCollapse.Panel>

            <CardCollapse.Panel
              key="projects"
              header={
                <>
                  <Icon type="project" /> <span style={{ marginLeft: '5px' }}>Projects</span>
                </>
              }
              extra={
                <>
                  <Tag>
                    {projects && projects.length} Project
                    {projects && projects.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                rowKey="projectId"
                dataSource={projects}
                columns={projectColumns}
                pagination={false}
                loading={loading}
                style={{ width: '100%' }}
              />
            </CardCollapse.Panel>
          </Collapse>
          <ExperienceLevelModal isModalVisible={isModalVisible} onCancel={() => { setIsModalVisible(false) }} />
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(SkillDetailPage, 'Skill Detail Page');

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  createCertification,
  updateCertification,
  toggleCertificationActivation
} from "api/employeeCertificationApi";
import { getAllCertifyingOrganizations } from "api/certifyingOrganizationApi";
import { getCertifications } from "api/employeeCertificationApi";
import { Typography, Form, Button, Input, Checkbox, Select } from "antd";
import ButtonRow from "components/common/ButtonRow";
import displayErrorNotification from "utils/displayErrorNotification";
import { omit } from "lodash";

const CertificationForm = ({
  certification,
  form,
  onSubmit,
  onCancel,
  isCreateMode
}) => {
  const [certifyingOrgs, setCertifyingOrgs] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const { getFieldDecorator } = form;
  const initialValues = isCreateMode
    ? {
        requireExpiration: true,
        certifyingOrganization: { id: "" }
      }
    : certification;

  useEffect(() => {
    Promise.all([getCertifications(true), getAllCertifyingOrganizations()])
      .then(results => {
        const sortedCertifications = results[0].sort((a, b) => a.name.localeCompare(b.name));
        const sortedOrgs = results[1].sort((a, b) => a.name.localeCompare(b.name));
        setCertifications(sortedCertifications);
        setCertifyingOrgs(sortedOrgs);
      })
      .catch(displayErrorNotification);

  }, []);

  const handleActivation = () => {
    toggleCertificationActivation(certification.id)
      .then(onSubmit)
      .catch(displayErrorNotification);
  };

  const handleSubmit = e => {
    e.preventDefault();

    form.validateFields((err, values) => {
      if (err) return;

      const certValues = {
        ...omit(values, "certifyingOrgId"),
        certifyingOrganization: certifyingOrgs.find(
          co => co.id === values.certifyingOrgId
        )
      };

      if (isCreateMode) {
        createCertification(certValues)
          .then(onSubmit)
          .catch(displayErrorNotification);
      } else {
        updateCertification(certification.id, certValues)
          .then(onSubmit)
          .catch(displayErrorNotification);
      }
    });
  };

  return (
    <Form
      layout="vertical"
      onSubmit={handleSubmit}
      style={{ maxWidth: "40em" }}
    >
      <Typography.Title>
        {isCreateMode ? "Add New" : "Edit"} Certification Type
      </Typography.Title>

      <Form.Item label="Certifying Organization" required>
        {getFieldDecorator("certifyingOrgId", {
          initialValue: initialValues.certifyingOrganization.id,
          rules: [
            {
              required: true,
              message: "Certifying Organization is a required field"
            }
          ]
        })(
          <Select
            showSearch
            allowClear
            placeholder="Select a Certifying Organization"
            optionFilterProp="children"
            disabled={!isCreateMode}
            filterOption={(inputValue, option) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
          >
            {certifyingOrgs.map(certifyingOrg => (
              <Select.Option key={certifyingOrg.id} value={certifyingOrg.id}>
                {certifyingOrg.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>

      <Form.Item label="Certification Name" required>
        {getFieldDecorator("name", {
          initialValue: initialValues.name,
          rules: [
            { required: true, message: "Name is a required field" },
            { max: 100, message: "Name must be fewer than 100 characters" },
            {
              validator: (_, value) =>
                value && value.trim() !== value
                  ? Promise.reject(
                      "Name cannot have leading or trailing spaces"
                    )
                  : Promise.resolve(),
            },
            {
              validator: (_, value) => {
                const selectedOrgId = form.getFieldValue("certifyingOrgId");
                const duplicate = certifications.some(
                  cert =>
                    cert.name.toLowerCase() === value.toLowerCase() &&
                    cert.certifyingOrganization.id === selectedOrgId
                );
                return duplicate
                  ? Promise.reject(
                      "A certification with this name already exists for the selected certifying organization"
                    )
                  : Promise.resolve();
              },
            },
          ]
        })(<Input />)}
      </Form.Item>
      <Form.Item required>
        {getFieldDecorator("requireExpiration", {
          initialValue: initialValues.requireExpiration,
          valuePropName: "checked"
        })(<Checkbox>Certification Requires Expiration Date?</Checkbox>)}
      </Form.Item>

      <ButtonRow style={{ marginTop: "1em" }}>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
        {!isCreateMode && (
          <Button onClick={handleActivation}>
            {certification && certification.inactive
              ? "Activate"
              : "Deactivate"}
          </Button>
        )}
      </ButtonRow>
    </Form>
  );
};

CertificationForm.propTypes = {
  certification: PropTypes.object,
  onCancel: PropTypes.func,
  isCreateMode: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default Form.create({ name: "certification_form" })(CertificationForm);

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Typography, Collapse, Icon, Tag, Alert } from 'antd';
import { map, groupBy, first } from 'lodash';
import UserContext from 'auth/UserContext';
import CardCollapse from 'components/common/collapse/CardCollapse';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import Stack from 'components/common/Stack';
import RoleTypeForm from './RoleTypeForm';
import displayErrorNotification from 'utils/displayErrorNotification';
import formatMonthDate from 'utils/formatMonthDate';
import formatDate from 'utils/formatDate';
import { trackComponent } from 'telemetry/AppInsights';
import { getRoleTypeById, getEmployeesByRoleTypeId, getRoleTypeExperienceByRoleTypeId } from 'api/RoleTypeApi';
import FullPageSpinner from 'components/common/FullPageSpinner';
import enumToDisplayText from 'utils/enumToDisplayText';
import { useFeatures } from '../../hooks/FeatureHooks';
import sorters from 'utils/sorters';
import ExperienceLevelModal from 'components/skills/skill/ExperienceLevelModal';

const RoleTypeDetailPage = props => {
  const features = useFeatures();
  const [isEditMode, setEditMode] = useState(false);
  const [roleType, setRoleType] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [projects, setProjects] = useState(null);
  const roleTypeId = props.match.params.roleTypeId;
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    getRoleTypeById(roleTypeId)
      .then(setRoleType)
      .catch(displayErrorNotification);
    getEmployeesByRoleTypeId(roleTypeId)
      .then(setEmployees)
      .catch(displayErrorNotification);

    getRoleTypeExperienceByRoleTypeId(roleTypeId)
      .then(response => {
        const projectGroups = groupBy(response, 'project.id');
        const projectMap = map(projectGroups, pg => {
          return {
            ...first(pg).project,
            employeeRoleTypes: pg.map(p => p.employeeRoleType)
          };
        });
        setProjects(projectMap);
      })
      .catch(displayErrorNotification);
  }, []);

  if (!roleType || !employees) return <FullPageSpinner />;

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = roleType => {
    setRoleType(roleType);
    toggleEditMode();
  };

  if (isEditMode) {
    return (
      <Stack>
        <RoleTypeForm onCancel={toggleEditMode} roleType={roleType} isCreateMode={false} onSubmit={handleSubmit} />
      </Stack>
    );
  }

  let activePanels = ['employees', 'projects'];
  let myMap = new Map();
  myMap.set('NONE', 0);
  myMap.set('LIMITED', 1);
  myMap.set('SOME', 2);
  myMap.set('SUBSTANTIAL', 3);
  myMap.set('EXTENSIVE', 4);
  myMap.set('SME', 5);


  const employeeColumns = [
    {
      title: 'Name',
      dataIndex: 'employee.fullName',
      key: 'employee.fullName',
      render: (text, record) => <Link to={`/employees/${record.employee.id}`}>{record.employee.fullName}</Link>,
      sorter: (a, b) => sorters.string(a.employee.fullName, b.employee.fullName)
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      key: 'employee.practice',
      sorter: (a, b) => sorters.string(a.employee.practice, b.employee.practice)
    },
    {
      title: 'Position',
      dataIndex: 'employee.title',
      key: 'employee.title',
      sorter: (a, b) => sorters.string(a.employee.title, b.employee.title)
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      key: 'employee.location',
      sorter: (a, b) => sorters.string(a.employee.location, b.employee.location)
    },
    {
      title:
        features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') ? (
          <>
            Experience Level
            <Icon type="info-circle" onClick={() => { setIsModalVisible(true) }} className="info-icon" />
          </>
        ) : (
          'Experience Level'
        ),
      dataIndex: 'experienceLevel',
      key: 'experienceLevel',
      render: (text, record) => enumToDisplayText(record.experienceLevel),
      sorter: (a, b) => (myMap.get(a.experienceLevel) > myMap.get(b.experienceLevel) ? 1 : -1)
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => sorters.string(a.duration, b.duration)
    },
    {
      title: 'Last Used',
      dataIndex: 'lastUsedDate',
      key: 'lastUsedDate',
      render: (text, record) => formatMonthDate(record.lastUsedDate),
      sorter: (a, b) => sorters.date(a.lastUsedDate, b.lastUsedDate)
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (text, record) => (record.interest ? 'Yes' : 'No'),
      sorter: (a, b) => (a.interest < b.interest ? -1 : 1)
    }
  ];

  const projectColumns = [
    {
      title: 'Client',
      dataIndex: 'client.name',
      sorter: (a, b) => sorters.string(a.client.name, b.client.name)
    },
    {
      title: 'Project',
      dataIndex: 'name',
      render: (name, record) => <Link to={`/projects/${record.id}`}>{name}</Link>,
      sorter: (a, b) => sorters.string(a.name, b.name)
    },
    {
      title: 'Active',
      dataIndex: 'active',
      align: 'center',
      render: record => <Tag color={record ? 'green' : 'red'}>{record ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => (a.active < b.active ? -1 : 1)
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: formatDate,
      sorter: (a, b) => sorters.date(a.startDate, b.startDate)
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      render: formatDate,
      sorter: (a, b) => sorters.date(a.endDate, b.endDate)
    }
  ];

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          {!roleType.active && (
            <Alert type="warning" showIcon style={{ marginBottom: '1rem' }} message="This project role is inactive" />
          )}
          <SplitPageHeaderLayout.Layout>
            <SplitPageHeaderLayout.Left>
              <Typography.Title level={1} style={{ marginBottom: '0' }}>
                {roleType.name}
                {ctx.user.permissions.canUpdateRoleTypeType && (
                  <Button
                    style={{ lineHeight: '0', fontSize: '20px' }}
                    icon="edit"
                    type="link"
                    onClick={toggleEditMode}
                  />
                )}
              </Typography.Title>
              <Typography.Title level={3} type="secondary" style={{ marginTop: '0', marginBottom: '1rem' }}>
                {roleType.family}
              </Typography.Title>

              {roleType.description && (
                <Typography.Paragraph style={{ marginTop: '0', marginBottom: '1rem' }}>
                  {roleType.description}
                </Typography.Paragraph>
              )}
            </SplitPageHeaderLayout.Left>
          </SplitPageHeaderLayout.Layout>

          <Collapse bordered={false} defaultActiveKey={activePanels}>
            <CardCollapse.Panel
              key="employees"
              header={
                <>
                  <Icon type="contacts" /> <span style={{ marginLeft: '5px' }}>Employees</span>
                </>
              }
              extra={
                <>
                  <Tag>
                    {employees && employees.length} Employee
                    {employees && employees.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                rowKey={record => record.employee.id}
                dataSource={employees}
                columns={employeeColumns}
                pagination={false}
                style={{ width: '100%' }}
              />
            </CardCollapse.Panel>

            <CardCollapse.Panel
              key="projects"
              header={
                <>
                  <Icon type="project" /> <span style={{ marginLeft: '5px' }}>Projects</span>
                </>
              }
              extra={
                <>
                  <Tag>
                    {projects && projects.length} Project
                    {projects && projects.length === 1 ? '' : 's'}
                  </Tag>
                </>
              }
            >
              <Table
                rowKey={record => record.id}
                dataSource={projects}
                columns={projectColumns}
                pagination={false}
                style={{ width: '100%' }}
              />
            </CardCollapse.Panel>
          </Collapse>
          <ExperienceLevelModal isModalVisible={isModalVisible} onCancel={() => { setIsModalVisible(false) }} />
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(RoleTypeDetailPage, 'Role Type Detail Page');

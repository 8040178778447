import React, { useEffect, useRef, useState } from "react";
import { Table, Typography, Icon } from "antd";
import Stack from "components/common/Stack";
import EmployeeFilters, { filterEmployees } from "./EmployeeFilters";
import EmployeeExportButton from "./EmployeeExportButton";
import {
  getAllEmployees,
  getAllEmployeesAdvancedSearch
} from "api/employeeApi";
import Toolbar from "components/common/Toolbar";
import { Link } from "react-router-dom";
import displayErrorNotification from "utils/displayErrorNotification";
import { trackComponent } from "telemetry/AppInsights";
import ButtonRow from "components/common/ButtonRow";
import { getAllRoleTypes, getRoleTypeFamilies } from "api/RoleTypeApi";
import { getRoleTypeFamilyTree } from "utils/RoleTypeDetails";
import { getCertifications } from "api/employeeCertificationApi";
import { getLandingPageSkill, getSkillCategories } from "api/skillsApi";
import {
  changeDelimiter,
  getFullSkillTree,
  textOverflow
} from "utils/skillDetails";
import { getPrompts } from "api/promptApi";
import EmployeeAdvancedSearchPanel from "./employee-advanced-search/EmployeeAdvancedSearchPanel";
import { filterAdvancedEmployees } from "./employee-advanced-search/EmployeeAdvancedSearchForm";
import formatDate from "utils/formatDate";
import EmployeeFullInfoModal from "./EmployeeFullInfoModal";
import useLocalStorage from "utils/useLocalStorage";
import {
  defaultAdvancedEmployeeFilterSettings,
  defaultEmployeeFilterSettings
} from "types/EmployeePreferences";
import {
  convertLandingsPageSkill,
  convertToEmployeeActive
} from "utils/convert";
import SavePreferencesButton from "components/common/SavePreferencesButton";
import { useUser } from "auth/UserHooks";
import { uniq } from "lodash";
import sorters from "utils/sorters";
import { getCapabilities } from "api/capabilitiesApi";

const EmployeeLandingPage = () => {
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [extraInfoHidden, setExtraInfoHidden] = useLocalStorage(
    "EmployeesLandingPage_ExtraInfoHidden",
    true
  );
  const [rowToDisplay, setRowToDisplay] = useState({});
  const [displayModal, setDisplayModal] = useState(false);

  const [roleTypes, setRoleTypes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [certifyingOrgNames, setCertifyingOrgNames] = useState([]);
  const [prompts, setPrompts] = useState([]);

  const [advancedSearchActive, setAdvancedSearchActive] = useLocalStorage(
    "EmployeesLandingPage_AdvancedSearchActive",
    false
  );
  const [filterSettings, setFilterSettings] = useLocalStorage(
    "EmployeesLandingPage_FilterSettings",
    defaultEmployeeFilterSettings()
  );
  const [advancedFilterSettings, setAdvancedFilterSettings] = useLocalStorage(
    "EmployeesLandingPage_AdvancedFilterSettings",
    defaultAdvancedEmployeeFilterSettings()
  );
  const [sortSettings, setSortSettings] = useLocalStorage(
    "EmployeesLandingPage_SortSettings",
    {}
  );
  const searchRef = useRef();
  const advancedSearchRef = useRef();
  const user = useUser();
  const isNotStaffing = !user.permissions.canReadStaffingPromptResponses;
  const [capabilities, setCapabilities] = useState([]);

  function _filterAdvancedEmployees(employees) {
    filterAdvancedEmployees(
      {
        employees: employees,
        onSearch: setFilteredEmployees,
        settings: advancedFilterSettings,
        setSettings: setAdvancedFilterSettings
      },
      {},
      {
        searchTerm: advancedFilterSettings.search,
        selectedPractices: advancedFilterSettings.practice,
        selectedPositions: advancedFilterSettings.position,
        selectedLevels: advancedFilterSettings.level,
        selectedRegions: advancedFilterSettings.region,
        selectedLocations: advancedFilterSettings.location,
        selectedEntities: advancedFilterSettings.entity,
        selectedCapabilities: advancedFilterSettings.capability,
        selectedMinorOrMajor: advancedFilterSettings.minorOrMajor,
        selectedSkills: advancedFilterSettings.skills,
        selectedSkillExpLevels: advancedFilterSettings.skillExpLevel,
        selectedSkillSearchType: advancedFilterSettings.skillSearchType,
        selectedCertifications: advancedFilterSettings.certification,
        selectedActive: advancedFilterSettings.certActive,
        selectedRoleTypes: advancedFilterSettings.roleType,
        selectedPRExpLevels: advancedFilterSettings.prExpLevel,
        selectedPrompts: advancedFilterSettings.prompt,
        selectedClients: advancedFilterSettings.client,
        selectedProjects: advancedFilterSettings.project,
        selectedHours: advancedFilterSettings.hours,
        selectedEndDate: advancedFilterSettings.endDate
      }
    );
  }

  function _filterEmployees(employees) {
    filterEmployees(
      {
        employees: employees,
        onSearch: setFilteredEmployees,
        settings: filterSettings,
        setSettings: setFilterSettings
      },
      {},
      {}
    );
  }
  useEffect(() => {
    setLoading(true);
    getCapabilities(true)
      .then(response => {
        const capabilities = [];
        response.forEach(capability => {
          capabilities.push(capability.name);
        });
        setCapabilities(capabilities);
      });
    if (advancedSearchActive) {
      Promise.all([
        getAllEmployees(),
        getSkillCategories(),
        getLandingPageSkill(),
        getAllRoleTypes(),
        getCertifications(false)
          .then(response => {
            setCertifications(response);
            const _certifyingOrgNames = uniq(
              response.map(c => c.certifyingOrganization.name)
            ).sort();
            setCertifyingOrgNames(_certifyingOrgNames);
          })
          .catch(displayErrorNotification),
        getPrompts(false).then(response => {
          setPrompts(
            response.filter(
              prompt =>
                (prompt.type === "SELECT_MULTIPLE" ||
                  prompt.type === "SELECT_ONE") &&
                !prompt.personal
            )
          );
        }),
        getRoleTypeFamilies()
      ])
        .then(results => {
          const converted = convertToEmployeeActive(results[0]);
          setEmployees(converted);
          getAllEmployeesAdvancedSearch()
            .then(response => {
              converted.map(e => {
                e.advancedSearch = response.find(r => r.id === e.id);
                return e;
              });
              _filterAdvancedEmployees(converted);
              setLoading(false);
              if (
                advancedSearchRef.current !== undefined &&
                advancedSearchRef.current.input !== null
              ) {
                advancedSearchRef.current.focus();
              }
            })
            .catch(displayErrorNotification);
          setSkills(
            getFullSkillTree(
              convertLandingsPageSkill(results[2], results[1], []),
              results[1]
            )
          );
          setRoleTypes(getRoleTypeFamilyTree(results[6], results[3]));
        })
        .catch(displayErrorNotification);
    } else {
      getAllEmployees()
        .then(employees => {
          const converted = convertToEmployeeActive(employees);
          setEmployees(converted);
          getAllEmployeesAdvancedSearch()
            .then(response => {
              converted.map(e => {
                e.advancedSearch = response.find(r => r.id === e.id);
                return e;
              });
            })
            .catch(displayErrorNotification);

          _filterEmployees(converted);

          setLoading(false);
          if (
            searchRef.current !== undefined &&
            searchRef.current.input !== null
          ) {
            searchRef.current.focus();
          }
        })
        .catch(displayErrorNotification);
      Promise.all([getRoleTypeFamilies(), getAllRoleTypes()])
        .then(results => {
          setRoleTypes(getRoleTypeFamilyTree(results[0], results[1]));
        })
        .catch(displayErrorNotification);
      Promise.all([getSkillCategories(), getLandingPageSkill()])
        .then(results => {
          const convertedSkills = convertLandingsPageSkill(
            results[1],
            results[0],
            []
          );
          setSkills(getFullSkillTree(convertedSkills, results[0]));
        })
        .catch(displayErrorNotification);
      getCertifications(false)
        .then(response => {
          setCertifications(response);
          const _certifyingOrgNames = uniq(
            response.map(c => c.certifyingOrganization.name)
          ).sort();
          setCertifyingOrgNames(_certifyingOrgNames);
        })
        .catch(displayErrorNotification);
      getPrompts(false).then(response => {
        setPrompts(
          response.filter(
            prompt =>
              (prompt.type === "SELECT_MULTIPLE" ||
                prompt.type === "SELECT_ONE") &&
              !prompt.personal
          )
        );
      });
    }
  }, []);

  const handleRowClick = record => {
    setRowToDisplay(record);
    setDisplayModal(true);
  };

  const closeModal = () => {
    setRowToDisplay({});
    setDisplayModal(false);
  };

  let columns = [
    {
      title: "Name",
      key: "name",
      render: record => (
        <Link to={`/employees/${record.id}`}>
          {record.lastName}, {record.firstName}
        </Link>
      ),
      sorter: (a, b) =>
        `${a.lastName}, ${a.firstName}` < `${b.lastName}, ${b.firstName}`
          ? -1
          : 1
    },
    {
      title: "Practice",
      dataIndex: "practice",
      key: "practice",
      sorter: (a, b) => sorters.string(a.practice, b.practice)
    },
    {
      title: "Business Unit",
      dataIndex: "businessUnit",
      key: "businessUnit",
      sorter: (a, b) => sorters.string(a.businessUnit, b.businessUnit)
    },
    {
      title: "Position",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => sorters.string(a.title, b.title)
    },
    {
      title: "Level",
      dataIndex: "level",
      key: "level",
      sorter: (a, b) => sorters.string(a.level + a.title, b.level + b.title)
    },
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      sorter: (a, b) => sorters.string(a.region, b.region)
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      sorter: (a, b) => sorters.string(a.location, b.location)
    },
    {
      title: "Credera Entity",
      dataIndex: "entity",
      key: "entity",
      sorter: (a, b) => sorters.string(a.entity, b.entity)
    },
    {
      title: "Major",
      dataIndex: "major",
      key: "major",
      render: (text, record) => (
        record.major === 'No Major' ?
          text
          :
          capabilities.includes(text) ?
            <div>
              <Icon type="wallet" style={{ paddingRight: '5px' }} />
              {text}
            </div>
            :
            <div>
              <Icon type="shop" style={{ paddingRight: '5px' }} />
              {text}
            </div>
      ),
      sorter: (a, b) => sorters.string(a.major, b.major)
    },
    {
      title: "Minor",
      dataIndex: "minor",
      render: (text, record) => (
        record.minor === 'No Minor' ?
          text
          :
          capabilities.includes(text) ?
            <div>
              <Icon type="wallet" style={{ paddingRight: '5px' }} />
              {text}
            </div>
            :
            <div>
              <Icon type="shop" style={{ paddingRight: '5px' }} />
              {text}
            </div>
      ),
      sorter: (a, b) => sorters.string(a.minor, b.minor)
    },
    {
      title: "Active Clients",
      dataIndex: "clients",
      render: client => textOverflow(changeDelimiter(client), 40),
      hidden: extraInfoHidden
    },
    {
      title: "Active Projects",
      dataIndex: "projects",
      render: project => textOverflow(changeDelimiter(project), 50),
      hidden: extraInfoHidden
    },
    {
      title: "Available Hours",
      dataIndex: "hours",
      key: "hours",
      render: hours => 40 - hours,
      hidden: extraInfoHidden,
      sorter: (a, b) => sorters.number(a.hours, b.hours)
    },
    {
      title: "Roll-Off Date",
      dataIndex: "endDate",
      key: "endDate",
      render: formatDate,
      sorter: (a, b) => sorters.date(a.endDate, b.endDate),
      hidden: extraInfoHidden
    },
    {
      title: "Staffing",
      dataIndex: "staffingPreferences",
      key: "staffingPreferences",
      render: staffingPreferences => textOverflow(staffingPreferences, 10),
      sorter: (a, b) =>
        sorters.string(a.staffingPreferences, b.staffingPreferences),
      hidden: isNotStaffing
    }
  ].filter(item => !item.hidden);

  columns = columns.map(column => {
    if (column.key === sortSettings.key) {
      return { ...column, defaultSortOrder: sortSettings.order };
    }
    return column;
  });

  function onChange(pagination, filters, sorter) {
    if (Object.keys(sorter).length > 0) {
      setSortSettings({
        key: sorter.columnKey,
        order: sorter.order
      });
    }
  }

  function handleCheck() {
    setExtraInfoHidden(!extraInfoHidden);
  }

  function _setAdvancedSearchActive(active) {
    setAdvancedSearchActive(active);
    if (active) {
      _filterAdvancedEmployees(employees);
    } else {
      _filterEmployees(employees);
    }
  }

  return (
    <Stack>
      <EmployeeFullInfoModal
        displayModal={displayModal}
        closeModal={closeModal}
        rowToDisplay={rowToDisplay}
      />
      <Typography.Title>
        Employees
        <ButtonRow style={{ float: "right" }}>
          <EmployeeExportButton
            filteredEmployees={filteredEmployees}
            showClients={!extraInfoHidden}
            loading={loading}
            setLoading={setLoading}
          />

          <SavePreferencesButton
            keys={[
              "EmployeesLandingPage_ExtraInfoHidden",
              "EmployeesLandingPage_FilterSettings",
              "EmployeesLandingPage_SortSettings",
              "EmployeesLandingPage_AdvancedSearchActive",
              "EmployeesLandingPage_AdvancedFilterSettings"
            ]}
            disabled={loading}
          />
        </ButtonRow>
      </Typography.Title>
      <EmployeeAdvancedSearchPanel
        onSubmit={setFilteredEmployees}
        employees={employees}
        active={advancedSearchActive}
        setActive={_setAdvancedSearchActive}
        extraInfoHidden={extraInfoHidden}
        disabled={loading}
        searchRef={advancedSearchRef}
        settings={advancedFilterSettings}
        setSettings={setAdvancedFilterSettings}
        roleTypes={roleTypes}
        skills={skills}
        certifications={certifications}
        certifyingOrgNames={certifyingOrgNames}
        prompts={prompts}
      />
      <Toolbar>
        <EmployeeFilters
          onSearch={setFilteredEmployees}
          employees={employees}
          handleCheck={handleCheck}
          extraInfoHidden={extraInfoHidden}
          advancedSearchActive={advancedSearchActive}
          settings={filterSettings}
          setSettings={setFilterSettings}
          disabled={loading}
          searchRef={searchRef}
          capabilities={capabilities}
        />
        <div>
          <span style={{ margin: "0 10px" }}>
            Showing {filteredEmployees.length} of {employees.length} Employees
          </span>
        </div>
      </Toolbar>

      <Table
        pagination={{ pageSize: 25, position: 'top' }}
        rowKey="id"
        columns={columns}
        dataSource={filteredEmployees}
        onChange={onChange}
        scroll={{ x: 1500 }}
        loading={loading}
        onRow={record => {
          return {
            onClick: () => handleRowClick(record),
            style: { cursor: "pointer" }
          };
        }}
      />
    </Stack>
  );
};

export default trackComponent(EmployeeLandingPage, "Employee Landing Page"); 
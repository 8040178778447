import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Typography, Divider } from 'antd';
import Markdown from 'react-markdown';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import FullPageSpinner from 'components/common/FullPageSpinner';

const BadgeInfo = ({ badge, onEdit }) => {
  if (!badge) return <FullPageSpinner />;

  return (
    <div>
      {badge.inactive && (
        <Alert type="warning" showIcon style={{ marginBottom: '1rem' }} message="This badge is inactive" />
      )}
      {badge.required && (
        <Alert type="info" showIcon style={{ marginBottom: '1rem' }} message="This badge is required." />
      )}
      <SplitPageHeaderLayout.Layout>
        <SplitPageHeaderLayout.Left>
          <Typography.Title level={1} style={{ marginBottom: '0' }}>
            {badge.name}
            {onEdit && (
              <Button
                style={{ lineHeight: '0', fontSize: '20px' }}
                icon="edit"
                type="link"
                onClick={onEdit}
              />
            )}
          </Typography.Title>
          <Typography.Title level={3} type="secondary" style={{ marginTop: '0' }}>
            {badge.description}
          </Typography.Title>
          <div>{badge.category.name}</div>
        </SplitPageHeaderLayout.Left>

        {badge.imagePath && (
          <SplitPageHeaderLayout.Right>
            <img style={{ maxWidth: 150 }} src={badge.imagePath} alt="Badge" />
          </SplitPageHeaderLayout.Right>
        )}
        {!badge.imagePath && badge.image && (
          <SplitPageHeaderLayout.Right>
            <img style={{ maxWidth: 150 }} src={badge.image} alt="Badge" />
          </SplitPageHeaderLayout.Right>
        )}
      </SplitPageHeaderLayout.Layout>

      <Divider />
      <div>
        <Markdown source={badge.content} />
      </div>
    </div>
  );
};

BadgeInfo.propTypes = {
  badge: PropTypes.object,
  onEdit: PropTypes.func
};

export default BadgeInfo;

import React, { useEffect, useState } from 'react';
import { getCertificationById } from 'api/employeeCertificationApi';
import { Alert, Button, Table, Typography, Tag } from 'antd';
import UserContext from 'auth/UserContext';
import Stack from 'components/common/Stack';
import { Link } from 'react-router-dom';
import SplitPageHeaderLayout from 'components/common/SplitPageHeaderLayout';
import { S3_IMAGE_BASE_URL } from 'config';
import CertificationForm from './CertificationForm';
import displayErrorNotification from 'utils/displayErrorNotification';
import formatDate from 'utils/formatDate';
import { trackComponent } from 'telemetry/AppInsights';
import FullPageSpinner from 'components/common/FullPageSpinner';
import { useFeatures } from 'hooks/FeatureHooks';

const CertificationDetailPage = props => {
  const features = useFeatures();
  const [isEditMode, setEditMode] = useState(false);
  const [certification, setCertification] = useState(null);
  const certificationId = props.match.params.certificationId;

  useEffect(() => {
    getCertificationById(certificationId)
      .then(setCertification)
      .catch(displayErrorNotification);
  }, []);

  if (!certification) return <FullPageSpinner />;

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = certification => {
    setCertification(certification);
    toggleEditMode();
  };

  if (isEditMode) {
    return (
      <Stack>
        <CertificationForm
          onCancel={toggleEditMode}
          certification={certification}
          isCreateMode={false}
          onSubmit={handleSubmit}
        />
      </Stack>
    );
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'employee.fullName',
      key: 'employee.fullName',
      render: (text, record) => <Link to={`/employees/${record.employee.id}`}>{record.employee.fullName}</Link>,
      sorter: (a, b) => a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase())
    },
    {
      title: 'Practice',
      dataIndex: 'employee.practice',
      key: 'employee.practice',
      sorter: (a, b) => a.employee.practice.toLowerCase().localeCompare(b.employee.practice.toLowerCase())
    },
    {
      title: 'Position',
      dataIndex: 'employee.title',
      key: 'employee.title',
      sorter: (a, b) => a.employee.title.toLowerCase().localeCompare(b.employee.title.toLowerCase())
    },
    {
      title: 'Office',
      dataIndex: 'employee.location',
      key: 'employee.location',
      sorter: (a, b) => a.employee.location.title.toLowerCase().localeCompare(b.employee.location.toLowerCase())
    },
    {
      title: 'Confirmation Number',
      dataIndex: 'confirmationNumber',
      key: 'confirmationNumber'
    },
    {
      title: 'Active Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: formatDate,
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      align: 'right'
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: formatDate,
      sorter: (a, b) => a.expirationDate.localeCompare(b.expirationDate),
      align: 'right'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: record => <Tag color={record ? 'green' : 'red'}>{record ? 'Active' : 'Inactive'}</Tag>,
      sorter: (a, b) => (a.active < b.active ? -1 : 1),
      align: 'right'
    }
  ];

  return (
    <UserContext.Consumer>
      {ctx => (
        <Stack>
          {certification.inactive && (
            <Alert type="warning" showIcon style={{ marginBottom: '1rem' }} message="This certification is inactive" />
          )}

          <SplitPageHeaderLayout.Layout>
            <SplitPageHeaderLayout.Left>
              <Typography.Title level={1} style={{ marginBottom: '0' }}>
                {certification.name}
                {ctx.user.permissions.canUpdateCertificationTypes && (
                  <Button
                    style={{ lineHeight: '0', fontSize: '20px' }}
                    icon="edit"
                    type="link"
                    onClick={toggleEditMode}
                  />
                )}
              </Typography.Title>
              <Typography.Title level={3} type="secondary" style={{ marginTop: '0' }}>
                <Link to={`/certifications/certifying_organizations/${certification.certifyingOrganization.id}`}>
                  {certification.certifyingOrganization.name}
                </Link>
              </Typography.Title>
            </SplitPageHeaderLayout.Left>

            {certification.certifyingOrganization.imagePath && (
              <SplitPageHeaderLayout.Right>
                <img
                  style={{ maxWidth: 150 }}
                  src={S3_IMAGE_BASE_URL + certification.certifyingOrganization.imagePath}
                  alt="Certifying Organization"
                />
              </SplitPageHeaderLayout.Right>
            )}
          </SplitPageHeaderLayout.Layout>

          <Typography.Title level={2}>Employees</Typography.Title>
          {features && features.isEnabled('ENABLE_PROJECTOR_FEATURES') && (
            <Typography.Paragraph>
              Certifications are periodically synced with Projector as Certification "Skills". Projector Certification
              of 0 = No Certification, 3 = Expired Certification, 5 = Active Certification.
            </Typography.Paragraph>
          )}
          <Table
            rowKey="id"
            dataSource={certification.employeeCertifications}
            columns={columns}
            pagination={false}
            footer={() => (
              <div style={{ textAlign: 'right' }}>
                {certification.employeeCertifications.length} Employee
                {certification.employeeCertifications.length === 1 ? '' : 's'}
              </div>
            )}
          />
        </Stack>
      )}
    </UserContext.Consumer>
  );
};

export default trackComponent(CertificationDetailPage, 'Certification Detail Page');
